import React from "react";

const Header = ({ view, setView, isMobile }) => {
  const changeView = (path) => {
    setView(path);
  };

  return (
    <div className="header">
      <h1 className="header-title">Tony Tallarico</h1>
      {!isMobile && <h2 className="roboto-narrow">An Editor on the Cutting Edge</h2>}
      <div className={`nav-bar ${isMobile ? "nav-bar-mobile" : ""}`}>
        <nav className="nav-links">
          <p className="nav-link" onClick={() => changeView("cars")}>Car Commercials</p>
          <p className="nav-link" onClick={() => changeView("musicvideo")}>Music Videos</p>
          <p className="nav-link" onClick={() => changeView("sports")}>Sports Integrations</p>
          <p className="nav-link" onClick={() => changeView("product")}>Product Integrations</p>
          <p className="nav-link" onClick={() => changeView("featurette")}>Featurettes</p>
        </nav>
      </div>
    </div>
  );
};

export default Header;
