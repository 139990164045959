import React, { useRef, useState } from 'react';
import { useVideo } from 'react-use';

const CustomVideoPlayer = ({ src, poster, autoPlay = false }) => {
    const videoRef = useRef(null);
    const [video, state, controls] = useVideo(
        <video
            ref={videoRef}
            src={src}
            poster={poster}
            width="100%"
            onClick={() => {
                if (state.paused) controls.play();
                else controls.pause();
            }}
            onMouseEnter={() => {
                setShowPauseIcon(true);
                clearTimeout(pauseIconTimeout);
                pauseIconTimeout = setTimeout(() => setShowPauseIcon(false), 2000);
            }}
            onMouseLeave={() => {
                clearTimeout(pauseIconTimeout);
                setShowPauseIcon(false);
            }}
        />
    );
    const [showPauseIcon, setShowPauseIcon] = useState(false);
    let pauseIconTimeout = null;

    const handleProgressClick = (event) => {
        const progressBar = event.target.getBoundingClientRect();
        const clickPosition = event.clientX - progressBar.left;
        const clickRatio = clickPosition / progressBar.width;
        controls.seek(clickRatio * state.duration);
    };

    React.useEffect(() => {
        if (autoPlay) controls.play();
    }, [autoPlay]);

    return (
        <div style={{ position: 'relative' }}>
            {video}
            <div
                style={{ height: '5px', background: '#ccc', width: '100%', cursor: 'pointer' }}
                onClick={handleProgressClick}
            >
                <div
                    style={{
                        height: '100%',
                        background: '#007bff',
                        width: `${(state.time / state.duration) * 100 || 0}%`,
                    }}
                />
            </div>
            {state.paused && (
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        opacity: 0.7,
                    }}
                >
                    <svg width="50" height="50" fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                        <path d="M4.5 3.5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0v-8a.5.5 0 0 1 .5-.5zm7 0a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0v-8a.5.5 0 0 1 .5-.5z" />
                    </svg>
                </div>
            )}
            {showPauseIcon && !state.paused && (
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        opacity: 0.7,
                    }}
                >
                    <svg width="50" height="50" fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                        <path d="M5.5 3.5A.5.5 0 0 1 6 4v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5zm5 0a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5z" />
                    </svg>
                </div>
            )}
        </div>
    );
};

export default CustomVideoPlayer;