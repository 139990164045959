import React, { useState, useEffect } from 'react';
import Thumbnail from './Thumbnail';
import CustomVideoPlayer from './CustomVideoPlayer';

const Gallery = ({ videos, isMobile }) => {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleThumbnailClick = (videoSrc) => {
    setSelectedVideo(videoSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedVideo(null);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
        closeModal();
    }
    };

    useEffect(() => {
        if (isModalOpen) {
            window.addEventListener('keydown', handleKeyDown);
        } else {
            window.removeEventListener('keydown', handleKeyDown);
        }
    
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isModalOpen]);
    

  return (
    <div>
        <div className='gallery-container'>
        <div className="gallery">
            {videos.map((video, index) => (
            <Thumbnail
                isMobile={isMobile}
                title={video.title}
                key={index}
                imageSrc={video.thumbnail}
                onClick={() => handleThumbnailClick(video.src)}
            />
            ))}
        </div>
       </div>
      {isModalOpen && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h3 className="modal-title">
            <div className = "modalHeader">
                <span className="modalTitle" >{selectedVideo && videos.find(v => v.src === selectedVideo)?.title}</span>
                 <button className="modal-close" onClick={closeModal}>Close</button>
                        </div>


            </h3>
            <CustomVideoPlayer src={selectedVideo} autoPlay={true} />

          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;