import React, { useState } from 'react';
import '../App.css';

const Thumbnail = ({ imageSrc, onClick, title, isMobile }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={`thumbnail-container ${isHovered ? 'hovered' : ''}`}
      onMouseEnter={() => !isMobile && setIsHovered(true)}
      onMouseLeave={() => !isMobile && setIsHovered(false)}
      onClick={onClick}
    >
      <img src={imageSrc} alt="Video Thumbnail" className="thumbnail-image" />
      <div className="thumbnail-overlay">
        <span className="thumbnail-title">
          {title}
        </span>
      </div>
    </div>
  );
};

export default Thumbnail;
