import { useState } from 'react';
import './App.css';
import './index.css';
import useWidowDimensions from './utils/useWindowDimensions';


import Header from './components/Header';
import Viewer from './components/Viewer'; 



function App() {

const [view, setView] = useState('sports')
const width = useWidowDimensions();

console.log("width: ", width);

  return (
    <div className="App">
      <Header view={view} 
              setView={setView} 
              isMobile={width < 768} />
      <Viewer view={view} 
              isMobile={width < 768} />

    </div>
  );
}

export default App;
