import React from "react";
import Gallery from "./Gallery";
import {
  musicVideos,
  carVideos,
  sportsVideos,
  productVideos,
  featuretteVideos,
} from "../data/thumbnailsAndClips";

const Viewer = ({ view, isMobile }) => {
  const getViewData = () => {
    switch (view) {
      case "cars":
        return { title: "Car Commercials", videos: carVideos };
      case "musicvideo":
        return { title: "Music Videos", videos: musicVideos };
      case "sports":
        return { title: "Sports Integrations", videos: sportsVideos };
      case "product":
        return { title: "Product Integrations", videos: productVideos };
      case "featurette":
        return { title: "Featurettes", videos: featuretteVideos };
      default:
        return { title: "Gallery", videos: [] };
    }
  };

  const { title, videos } = getViewData();

  return (
    <div>
      <div className="view-header">
        <h2 className="view-title">{title}</h2>
      </div>
      <Gallery videos={videos} isMobile={isMobile} />
    </div>
  );
};

export default Viewer;
