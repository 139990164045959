export const carVideos = [
    {
        thumbnail: '/content/Still/Cars/FS-LYRIQ.png',
        src: '/content/Video/Cars/FS-LYRIQ.mp4',
        title: 'Cadillac Lyriq',
    },
    {
        thumbnail: '/content/Still/Cars/FS1CADILLAC-LeaderOfThePack.png',
        src: '/content/Video/Cars/FS1CADILLAC-LeaderOfThePack.mp4',
        title: 'Cadillac XT6',
    },
    {
      thumbnail: '/content/Still/Cars/FoxSports_GMC_TNF.png',
      src: '/content/Video/Cars/FoxSports_GMC_TNF.mp4',
      title: 'GMC Denali',
  },
  {
    thumbnail: '/content/Still/Cars/FS_GMC-ElectricPerfection.png',
    src: '/content/Video/Cars/FS_GMC-ElectricPerfection.mp4',
    title: 'GMC Hummer EV',
},
{
  thumbnail: '/content/Still/Cars/FS_GMC_PASSENGERSEAT.png',
  src: '/content/Video/Cars/FS_GMC_PASSENGERSEAT.mp4',
  title: 'GMC Denali Ultimate',
},
{
    thumbnail: '/content/Still/Cars/FS_GMC-Cleatus.png',
    src: '/content/Video/Cars/FS_GMC-Cleatus.mp4',
    title: 'GMC Denali Yukon',
  },
    // Add more video objects
];



export const musicVideos = [
    {
        thumbnail: '/content/Still/MusicVideo/LisaF_MusicVideo.png',
        src: '/content/Video/MusicVideo/LisaF_MusicVideo.mp4',
        title: "Jojo - Can't Fight This Feeling / Lisa Frankenstein",
    },
    {
        thumbnail: '/content/Still/MusicVideo/LSB_S3_Trailer.png',
        src: '/content/Video/MusicVideo/LSB_S3_Trailer.mp4',
        title: 'Lip Sync Battle S3: Beat It',
    },
    {
        thumbnail: '/content/Still/MusicVideo/THBY_S3_RockMyKiss.png',
        src: '/content/Video/MusicVideo/THBY_S3_RockMyKiss.mp4',
        title: 'The Boys: Rock My Kiss',
    },

    // Add more video objects
];



export const sportsVideos = [

    {
        thumbnail: '/content/Still/SportsIntegration/PizzaHutSuperbowl2023.png',
        src: '/content/Video/SportsIntegration/PizzaHutSuperbowl2023.mp4',
        title: 'Von Miller / Pizza Hut'
    },
    {
        thumbnail: '/content/Still/SportsIntegration/ESPN_KFP4-CoachJack.png',
        src: '/content/Video/SportsIntegration/ESPN_KFP4-CoachJack.mp4',
        title: 'ESPN: Kung-Fu Panda 4'
    },
    {
        thumbnail: '/content/Still/SportsIntegration/ESPN_Nope.png',
        src: '/content/Video/SportsIntegration/ESPN_Nope.mp4',
        title: 'ESPN: Steph Curry / Nope'
    },
    {
        thumbnail: '/content/Still/SportsIntegration/ESPN_Croods2_30.png',
        src: '/content/Video/SportsIntegration/ESPN_Croods2_30.mp4',
        title: 'Trae Young / Croods 2',
    }, 
    {
        thumbnail: '/content/Still/SportsIntegration/ESPN_TopGunMaverick.png',
        src: '/content/Video/SportsIntegration/ESPN_TopGunMaverick.mp4',
        title: 'ESPN: Top Gun: Maverick'
    },
    {
        thumbnail: '/content/Still/SportsIntegration/FS-PIZZAHUT-BoothParty.png',
        src: '/content/Video/SportsIntegration/FS-PIZZAHUT-BoothParty.mp4',
        title: 'Fox Sports: Pizza Hut Booth Party'
    },
    {
        thumbnail: '/content/Still/SportsIntegration/GhostBusters-TravisKelce.png',
        src: '/content/Video/SportsIntegration/GhostBusters-TravisKelce.mp4',
        title: 'Travis Kelce / Ghostbusters Afterlife'
    },

    {
        thumbnail: '/content/Still/SportsIntegration/KnockAtTheCabin.png',
        src: '/content/Video/SportsIntegration/KnockAtTheCabin.mp4',
        title: 'James Harden / Knock At The Cabin',
    },
    {
        thumbnail: '/content/Still/SportsIntegration/MARIO_SuperKicks.png',
        src: '/content/Video/SportsIntegration/MARIO_SuperKicks.mp4',
        title: ' Klay Thompson / The Super Mario Bros',
    },
    {
        thumbnail: '/content/Still/SportsIntegration/GoldenBoot.png',
        src: '/content/Video/SportsIntegration/GoldenBoot.mp4',
        title: "World Cup '22 / Puss In Boots",
    },
    {
        thumbnail: '/content/Still/SportsIntegration/SpaceJam2-InsidetheNBA.png',
        src: '/content/Video/SportsIntegration/SpaceJam2-InsidetheNBA.mp4',
        title: 'Inside The NBA / Space Jam 2',
    },
    {
        thumbnail: '/content/Still/SportsIntegration/Morbius-VonMiller.png',
        src: '/content/Video/SportsIntegration/Morbius-VonMiller.mp4',
        title: 'Von Miller / Morbius',
    },

    // Add more video objects
];



export const productVideos = [
    {
        thumbnail: '/content/Still/ProductIntegration/DnD-OldSpice.png',
        src: '/content/Video/ProductIntegration/DnD-OldSpice.mp4',
        title: 'Dungeons & Dragons: Old Spice',
    },
    {
        thumbnail: '/content/Still/ProductIntegration/BeetleJuiceSecret.png',
        src: '/content/Video/ProductIntegration/BeetleJuiceSecret.mp4',
        title: 'Beetlejuice Beetlejuice: Secret Whole Body',
    },    
    {
        thumbnail: '/content/Still/ProductIntegration/GhostBustersVR.png',
        src: '/content/Video/ProductIntegration/GhostBustersVR.mp4',
        title: 'Ghostbusters: Meta Quest',
    },
    {
        thumbnail: '/content/Still/ProductIntegration/Lego-SpaceJam2.png',
        src: '/content/Video/ProductIntegration/Lego-SpaceJam2.mp4',
        title: 'Space Jam 2: Lego',
    },
    {
        thumbnail: '/content/Still/ProductIntegration/JeffRoss-DegreeMen.png',
        src: '/content/Video/ProductIntegration/JeffRoss-DegreeMen.mp4',
        title: 'Comedy Central Roast: Degree Men',
    },
    {
        thumbnail: '/content/Still/ProductIntegration/WendysBigNoonKickoff.png',
        src: '/content/Video/ProductIntegration/WendysBigNoonKickoff.mp4',
        title: "Big Noon Kickoff: Wendy's",
    },  
   
    {
        thumbnail: '/content/Still/ProductIntegration/DirectTV-GhostAdventures.png',
        src: '/content/Video/ProductIntegration/DirectTV-GhostAdventures.mp4',
        title: 'DirectTV: Ghost Adventures',
    },

    {
        thumbnail: '/content/Still/ProductIntegration/PUMA_LaMeloSlime.png',
        src: '/content/Video/ProductIntegration/PUMA_LaMeloSlime.mp4',
        title: 'Nickelodeon: Puma',
    },

      {
        thumbnail: '/content/Still/ProductIntegration/SonicArcher.png',
        src: '/content/Video/ProductIntegration/SonicArcher.mp4',
        title: 'Archer: Sonic',
    },    







    // Add more video objects
];



export const featuretteVideos = [
    {
        thumbnail: '/content/Still/Featurette/Zombieland2.png',
        src: '/content/Video/Featurette/Zombieland2.mp4',
        title: "Zombieland 2: The Legend of Tallahassee",
    },
    {
        thumbnail: '/content/Still/Featurette/WhatisWhatif.png',
        src: '/content/Video/Featurette/WhatisWhatif.mp4',
        title: "What Is What If?",
    },
    {
        thumbnail: '/content/Still/Featurette/TheHuntsmanWintersWar.png',
        src: '/content/Video/Featurette/TheHuntsmanWintersWar.mp4',
        title: "Lifetime Special Look: The Huntsman Winter's War",
    },
    {
        thumbnail: '/content/Still/Featurette/PainHustlers.png',
        src: '/content/Video/Featurette/PainHustlers.mp4',
        title: "Pain Husters: Casting Florida",
    },
    {
        thumbnail: '/content/Still/Featurette/JungleCruiseSecretSkipper.png',
        src: '/content/Video/Featurette/JungleCruiseSecretSkipper.mp4',
        title: "Jungle Cruise: Secret Skipper",
    },
    {
        thumbnail: '/content/Still/Featurette/EverybodyKnows.png',
        src: '/content/Video/Featurette/EverybodyKnows.mp4',
        title: "Everybody Knows",
    },

    // Add more video objects
];






export const sampleVideos = [
    {
        thumbnail: '/content/Still/MusicVideo/LisaF_MusicVideo.png',
        src: '/content/Video/MusicVideo/LisaF_MusicVideo.mp4',
        title: "Jojo - Can't Fight This Feeling / Lisa Frankenstein",
    },


    // Add more video objects
];
